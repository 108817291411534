import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticImage } from "gatsby-plugin-image"

import Layout from '../components/Layout';
import SectionContainer from "../components/common/SectionContainer";
import SectionColumn from "../components/common/SectionColumn";
import Card from "../components/common/Card";

import SEO_IMage from "../assets/images/header.jpg";

const IndexPage = () => {
    const htmlAttrs = {
        lang: 'de-DE'
    }

    const pageMeta = [
        {
            name: `description`,
            content: 'leadinsight identifiziert, welche Unternehmen Ihre Webseiten besuchen.',
        },
        {
            property: `og:title`,
            content: 'leadinsight - Besuchererkennung & Leadgenerierung',
        },
        {
            property: `og:description`,
            content: 'Identifizieren Sie Ihre Webseite-Besucher.',
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:creator`,
            content: '@troepsi',
        },
        {
            name: `twitter:title`,
            content: 'leadinsight - Besuchererkennung & Leadgenerierung',
        },
        {
            name: `twitter:description`,
            content: 'Identifizieren Sie Ihre Webseite-Besucher.',
        },
        {
            name: `keywords`,
            content: 'besuchererkennung, besucher, identifizieren, lead, leadgeneriereung, kmu',
        }
    ];

    return (
        <Layout pageName="home">
            <Helmet htmlAttributes={htmlAttrs} meta={pageMeta}>
                <title>leadinsight - Besuchererkennung</title>

                <base target="_blank" href="https://www.leadinsight.de/" />

                <meta property="og:image" content={SEO_IMage} />

                <script type="application/ld+json">{
                    `{
                        "@context": "https://schema.org/",
                        "@type": "WebSite",
                        "name": "leadinsight",
                        "url": "https://www.leadinsight.de",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+4927138478920",
                            "contactType": "Anfrage"
                          }
                    }`
                }</script>
            </Helmet>

            <SectionContainer className={`visits-section bg-dark text-white pt-5`}>
                <SectionColumn columnWidthMedium={12}>
                    <div className="text-center mb-5">
                        <h1>Besucher-Erkennung für Ihre Websiten</h1>
                        <p className="lead mb-4">Identifizieren Sie Ihre Website-Besucher <br/> und erhalten Sie Einblicke in die Interessen Ihrer Kunden.</p>
                        <a href="https://app.leadinsight.de" className="btn btn-primary me-1">Anmelden</a>
                        <a href="mailto:support@leadinsight.de" className="btn btn-secondary-light">Kontakt</a>
                    </div>
                </SectionColumn>
                <SectionColumn columnWidthMedium={8} classes="mx-auto">
                    <StaticImage src="../assets/images/header.png" className="" alt="Besucher-Erkennung für Webseiten" />
                </SectionColumn>
            </SectionContainer>

            <SectionContainer className={`visits-section py-5`}>
                <SectionColumn columnWidthMedium={8} classes="mx-auto mb-5">
                    <div className="text-center">
                        <h2>Funktionen</h2>
                        <p className="lead">leadinsight identifiziert, welche Unternehmen Ihre Webseiten besuchen. Durch die intuitive Verwaltung, erhalten Sie einen Überblick über potentielle Kunden und können schneller reagieren, als Ihre Konkurrenz.</p>
                    </div>
                </SectionColumn>
                <div />
                <SectionColumn columnWidthMedium={4} classes="mx-auto">
                    <Card classes="text-center mb-3">
                        <h3 className="h5">Identifizierung</h3>
                        <p>Bekommen Sie einen Einblick, wer Ihre Webseiten besucht. Alle Unternehmen auf einen Blick.</p>
                    </Card>
                </SectionColumn>
                <SectionColumn columnWidthMedium={4} classes="mx-auto">
                    <Card classes="text-center mb-3">
                        <h3 className="h5">Profile</h3>
                        <p>Durch unseren Algorithmus bekommen Sie ein umfangreiches Unternehmensprofil präsentiert.</p>
                    </Card>
                </SectionColumn>
                <SectionColumn columnWidthMedium={4} classes="mx-auto">
                    <Card classes="text-center mb-3">
                        <h3 className="h5">Statistiken</h3>
                        <p>Übersicht über die Dauer des Besuchs, Anzahl der Seiten, Wiederkehrende Besuche und Inhalte.</p>
                    </Card>
                </SectionColumn>
                <SectionColumn columnWidthMedium={4} classes="mx-auto">
                    <Card classes="text-center mb-3">
                        <h3 className="h5">Berichte</h3>
                        <p>Erhalten Sie tägliche, wöchentliche oder monatliche Berichte bequem per E-Mail.</p>
                    </Card>
                </SectionColumn>
                <SectionColumn columnWidthMedium={4} classes="mx-auto">
                    <Card classes="text-center mb-3">
                        <h3 className="h5">Kampagnen</h3>
                        <p>Sehen Sie, welche Werbe-Kampagnen mit welchen Keywords die Besuche gerneriert.</p>
                    </Card>
                </SectionColumn>
                <SectionColumn columnWidthMedium={4} classes="mx-auto">
                    <Card classes="text-center mb-3">
                        <h3 className="h5">Auf jedem Gerät</h3>
                        <p>leadinsight funktioniert auf jedem modernen PC, Notebook, Tablet und Smartphone.</p>
                    </Card>
                </SectionColumn>
            </SectionContainer>

            <SectionContainer className={`pb-5 pt-5 pb-md-0 bg-dark`}>
                <SectionColumn columnWidthMedium={6} classes="mx-auto mb-3 mb-md-0">
                    <StaticImage src="../assets/images/visits-detail.jpg" className="border-top border-end border-start" alt="Besucher-Erkennung für Webseiten" />
                </SectionColumn>
                <SectionColumn columnWidthMedium={6} classes="mx-auto">
                    <StaticImage src="../assets/images/visits.jpg" className="border-top border-end border-start" alt="Besucher-Erkennung für Webseiten" />
                </SectionColumn>
            </SectionContainer>

            <SectionContainer className={`visits-section bg-primary text-white py-5`}>
                <SectionColumn columnWidthMedium={6} classes="mx-auto">
                    <div className="text-center">
                        <h2>Interesse, Ihre Besucher <br/> zu identifizieren?</h2>
                        <p>Aktuell bieten wir noch keine öffentliche Registrierung an. Sprechen Sie uns für ein persönliches Angebot an. Oder haben Sie noch Fragen? Wir helfen Ihnen gerne weiter.</p>
                        <p className="lead"><a className="text-white fw-bold" href="tel:+4927138478920">+49 (0) 271 / 38 47 89 2-0</a> / <a className="text-white fw-bold" href="mailto:info@hype-media.de">info@hype-media.de</a></p>
                    </div>
                </SectionColumn>
            </SectionContainer>
        </Layout>
    );
};

export default IndexPage;
