import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

Card.propTypes = {
	title: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.object,
		PropTypes.func,
		PropTypes.string
	]).isRequired,
	footer: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string
	]),
	body: PropTypes.bool,
	simple: PropTypes.bool,
	shadow: PropTypes.bool,
	footerRight: PropTypes.bool,
};

function Card(props) {
	const { title, children, footer, body, simple, shadow, classes, footerRight } = props;
	let titleContent = "";
	let bodyContent = "";
	let footerContent = "";

	if(title) {
		titleContent = <h5 className="card-header">{title}</h5>;
	}

	if(body && !simple) {
		bodyContent = (
			<div className="card-body d-flex flex-column">
				{children}
			</div>
		)
	} else {
		bodyContent = children
	}

	if(footer) {
		footerContent = (
			<div className={classnames("card-footer", { "text-right": footerRight })}>
				{footer}
			</div>
		)
	}

	return (
		<div className={classnames("card", { "card-body": simple, "shadow-sm": shadow }, classes)}>
			{titleContent}
			{bodyContent}
			{footerContent}
		</div>
	);
}

Card.defaultProps = {
	body: true,
	simple: false,
	shadow: false,
	classes: "",
	footerRight: true,
}

export default Card;
